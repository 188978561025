import React from "react";
import { aboutus, gradient } from "../../../../constants/images";
import { Button } from "../../../../components/ui";
import { Link } from "react-router-dom";

const AboutUsSection = () => {
  return (
    <div>
      <div className="mx-auto flex flex-col px-8 py-12 md:flex-row md:gap-5 lg:gap-[22rem] lg:px-[9rem]  lg:py-40">
        <div className="order-last mt-10 flex items-start md:order-first md:mt-0">
          <img src={aboutus} alt="about us" className="md:max-w-full lg:max-w-[250%]" />
          <img
            src={gradient}
            alt="gradient"
            className="mt-10 hidden md:block md:max-w-full lg:min-w-[200px]"
          />
        </div>

        <div className="order-first flex flex-col space-y-4 md:order-last md:w-[70%] lg:w-full">
          <div className="hidden items-end md:flex">
            <hr color="#5f5f5f" className="w-[15%]" />
            <span className="font-normal capitalize text-lightgray lg:text-lg">about us</span>
          </div>

          <span className="text-[25px] font-semibold text-secondary lg:text-[40px]">
            We are Redefining <br />
            Service Connections
          </span>
          <span className="font-normal text-lightgray lg:text-lg">
            Get Your Work Done Effortlessly (GYWDE) is a system that connect vendors (physical and
            online) to people in need of their service. At GYWDE, we believe that everyone deserves
            access to quality services that make life more convenient and fulfilling.
          </span>
          <Link to={"/about-us"}>
            <Button children="Learn more" border="none" className="w-fit" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AboutUsSection;
