import React, { useEffect, useState } from "react";
import { heroBgSlides } from "../../../../constants";
import useInterval from "../../../../hooks/useInterval";
import { Button } from "../../../../components/ui";
import { Link } from "react-router-dom";

const HeroSection = () => {
  const [bgSlides, setBGSlides] = useState([]);
  const [currentBGSlideIndex, setCurrentBGSlideIndex] = useState(-1);

  useInterval(
    () => {
      const nextIndex = currentBGSlideIndex >= bgSlides.length - 1 ? 0 : currentBGSlideIndex + 1;
      setCurrentBGSlideIndex(nextIndex);
    },
    bgSlides.length ? 6000 : null,
  );

  useEffect(() => {
    setBGSlides(heroBgSlides);
  });
  return (
    <main className="relative">
      <div>
        {" "}
        <div
          className="relative h-full overflow-hidden bg-cover bg-[bottom_-3rem_left_-30rem] bg-no-repeat px-6 py-8 transition-all duration-1000 ease-out md:bg-[bottom_-3rem_left_-37rem] md:px-10 md:py-20 md:pb-0 lg:bg-center xl:p-36 xl:pb-0"
          style={{
            backgroundImage: `url(${bgSlides[currentBGSlideIndex]?.background || "/home_bg.svg"})`,
          }}
        >
          <div className="mt-20 h-[300px] md:h-[500px]">
            <div className="flex flex-col space-y-20 text-white transition-all duration-1000 ease-out md:space-y-40 ">
              <div className="flex animate-slide_up flex-col gap-2 lg:w-[40%]">
                <span className="text-3xl font-bold leading-tight md:text-[40px] lg:text-[50px] ">
                  {bgSlides[currentBGSlideIndex]?.title || "Find quality service vendors and "}
                </span>
                <span className="text-3xl font-bold leading-tight text-lightblue md:text-[40px] lg:text-[50px]">
                  {bgSlides[currentBGSlideIndex]?.text || " Get Your Work Done"}
                </span>
                <span className="text-3xl font-bold italic leading-tight md:text-[40px] lg:text-[50px]">
                  {bgSlides[currentBGSlideIndex]?.subtext || " ...Effortlessly"}
                </span>
                <Link to="/coming-soon">
                  <Button
                    children="Get Started"
                    border="none"
                    className="mt-7 w-fit text-sm font-medium md:mt-10 md:text-base"
                  />
                </Link>
              </div>
            </div>
          </div>
          <div className="mx-auto flex items-center justify-center gap-1 pt-8 md:py-8">
            <span
              className={`h-[10px] w-[10px] rounded-full border-[1px] border-solid border-white ${
                currentBGSlideIndex === 0 && "bg-white"
              } delay-250 transition-all ease-linear`}
            ></span>
            <span
              className={`h-[10px] w-[10px] rounded-full border-[1px] border-solid border-white ${
                currentBGSlideIndex === 1 && "bg-white"
              } delay-250 transition-all ease-linear`}
            ></span>
          </div>
        </div>
      </div>
    </main>
  );
};

export default HeroSection;
