import React, { useEffect } from "react";
import {
  ApartSection,
  BannerSection,
  CommitmentSection,
  ContactSection,
  FAQSSection,
  HeroSection,
  MissionSection,
} from "./_components";
import { Banner, Footer, Navbar } from "../../components/ui";
import { useLocation } from "react-router-dom";

const AboutUs = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [pathname]);

  return (
    <>
    <Navbar />
      <HeroSection />
      <MissionSection />
      <CommitmentSection />
      <ApartSection />
      <BannerSection />
      <div className="flex gap-10 lg:flex-row flex-col py-20">
        <FAQSSection />
        <ContactSection />
      </div>
      <Banner />
      <Footer />
    </>
  );
};

export default AboutUs;
