import React from "react";
import { mission } from "../../../../constants/images";

const MissionSection = () => {
  return (
    <div>
      <div className="mx-auto flex flex-col px-8 py-12 md:flex-row md:gap-10 lg:gap-[22rem] lg:px-[9rem] lg:py-40  lg:pb-20">
        <div className="order-last mt-10 flex items-start md:order-first md:mt-0">
          <img src={mission} alt="about us" className="md:max-w-full lg:max-w-[250%]" />
        </div>

        <div className="order-first flex flex-col justify-around gap-6 md:order-last md:w-[70%] lg:w-full lg:gap-0">
          <div className="flex flex-col">
            <span className="text-[25px] font-semibold text-secondary md:text-[20px] lg:text-[30px]">
              Our Mission
            </span>
            <span className="font-normal text-lightgray lg:text-lg">
              To empower individuals and businesses by providing a seamless platform that connects
              consumers with skilled vendors, enabling the efficient completion of tasks, projects,
              and services while fostering trust, professionalism, and mutual growth.
            </span>
          </div>
          <div className="flex flex-col">
            <span className="text-[25px] font-semibold text-secondary md:text-[20px] lg:text-[30px]">
              Our Vison
            </span>
            <span className="font-normal text-lightgray lg:text-lg">
              To be the leading global platform for connecting consumers and vendors,
              revolutionizing how services are accessed and delivered by creating a safe, reliable,
              and collaborative environment for all.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MissionSection;
