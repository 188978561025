import { useLocation } from "react-router-dom";
import { Banner, Footer, Navbar } from "../../components/ui";
import ComingSoonImg from "./assets/coming_soon.svg";
import { useEffect, useState } from "react";

function NoticePage() {
  const { pathname } = useLocation();
  const [slideIn, setSlideIn] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSlideIn(false);
    }, 800);

    return () => clearTimeout(timeoutId);
  }, []);


  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [pathname]);

  return (
    <>
      <Navbar />
      <div className="mx-auto my-0 mt-6 max-w-[1280px] p-8 md:mt-20 md:text-center">
        <div className="flex flex-col gap-y-3 py-10 md:items-center md:justify-center md:gap-y-5">
          <img
            src={ComingSoonImg}
            alt="coming soon"
            className={`mb-4 mt-4 w-3/4 object-contain md:mb-5 md:w-[40%]  ${
              slideIn ? "animate-slide_right" : ""
            } animate-bounce`}
          />
          <span className="text-[25px] font-semibold text-secondary md:text-[40px]">
            Web App Coming Soon!
          </span>
          <span className="text-base font-normal text-lightgray md:text-lg">
            Our web app is currently under development and isn't available yet. In the meantime, you
            can access all our features and services through our{" "}
            <span className="font-bold">mobile app</span>. Please download it below from the App
            Store or Google Play to get started.
          </span>
        </div>
      </div>
      <Banner />
      <Footer />
    </>
  );
}

export default NoticePage;
