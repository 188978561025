import React from "react";
import { Button } from "../../../../components/ui";

const ContactSection = () => {
  return (
    <div className="bg-[#0096C1] lg:w-[40%] lg:px-0 md:mx-10 max-w-full lg:max-w-[50%]">
      <div className="flex flex-col px-8 md:px-12 lg:py-7 py-6 justify-evenly h-full text-primary">
        <div className="flex flex-col">
          <span className="md:text-[20px] text-lg">Have more questions?</span>
          <span className="md:text-[30px] text-[25px] font-semibold">
            Contact Us
          </span>
        </div>

        <form method="post" action="/" className="flex flex-col gap-6">
          <div className="flex flex-col">
            <label htmlFor="name" className="lg:text-lg text-base">
              Your Name
            </label>
            <input
              type="text"
              id="name"
              className="outline-none rounded-[5px] p-4"
              required
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="email" className="lg:text-lg text-base">
              Your Email
            </label>
            <input
              type="email"
              id="email"
              className="outline-none rounded-[5px] p-4"
              required
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="question" className="lg:text-lg text-base">
              Your Question
            </label>
            <textarea
              type="text"
              id="question"
              className="outline-none rounded-[5px] p-12 pl-4 pt-4 text-secondary"
              required
            />
          </div>
        </form>
        <Button
          backgroundColor={"#ffffff"}
          textColor={"#002D3A"}
          border="none"
          type="submit"
          children="Get Started"
          className="w-fit lg:mt-3 mt-6"
        />
      </div>
    </div>
  );
};

export default ContactSection;
